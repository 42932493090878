













































































import { Component } from 'vue-property-decorator'
import { formatTimestamp } from '@/utils/utils'
import VueBase from '@/VueBase'

@Component({ name: 'TaintPool' })
export default class TaintPool extends VueBase {
  private page = 1
  private pageSize = 20
  private dataEnd = false
  private tableData: any = []
  private Base64 = require('js-base64').Base64

  private temp_title = ''
  private temp_header = null
  private temp_json = null
  private dialogVisible = false

  created() {
    this.getTableData()
  }
  private reset() {
    this.newSelectData()
  }

  private newSelectData() {
    this.page = 1
    this.tableData = []
    this.getTableData()
  }

  mounted() {
    window.addEventListener('scroll', this.myScroll)
  }

  beforeDestroy() {
    window.removeEventListener('scroll', this.myScroll)
  }

  private myScroll() {
    const bottomWindow =
      document.documentElement.scrollTop + window.innerHeight >
      document.documentElement.offsetHeight - 1
    if (bottomWindow) {
      if (!this.dataEnd) {
        this.page += 1
        this.getTableData()
      }
    }
  }

  private async getTableData() {
    const params = {
      page: this.page,
      pageSize: this.pageSize,
    }
    this.loadingStart()
    const { status, data, msg } = await this.services.taint.methodList(params)
    this.loadingDone()
    if (status !== 201) {
      this.$message({
        type: 'error',
        message: msg,
        showClose: true,
      })
      return
    }
    const tableData = data.reduce((list: any, item: any) => {
      list.push({
        ...item,
        create_time: formatTimestamp(item.create_time),
        update_time: formatTimestamp(item.update_time),
        req_header: this.Base64.decode(item.req_header),
      })
      return list
    }, [])
    if (tableData.length < 20) {
      this.dataEnd = true
    }
    this.tableData = [...this.tableData, ...tableData]
  }

  projectNameSplit(name: string, total: number) {
    if (name.length > total) {
      return name.substring(0, total)
    }
    return name
  }

  showMethodPool(row: any, column: any, cell: any, event: any) {
    this.temp_header = null
    this.temp_json = null
    if (column.label === this.$t('views.scaList.tableHeaders.method_pool')) {
      this.temp_title = column.label
      this.temp_json = row.method_pool
    } else if (
      column.label === this.$t('views.scaList.tableHeaders.req_header')
    ) {
      this.temp_title = column.label
      this.temp_header = row.req_header.replace(/\n/gm, '<br/>')
    }
    this.dialogVisible = true
  }

  handleClose(done: any) {
    this.$confirm(this.$t('views.scaList.tableHeaders.close') as string).then(
      (_) => {
        done()
      }
    )
  }
}
